<template>
  <div class="w-full mw-custom">
    <div v-if="!filename && Object.keys(files).length === 0" class="flex flex-col items-center pt-s128">
      <BrandIcon class="text-red h-11 w-11" />
      <div class="mt-2 text-sm leading-6 text-center text-primary" style="max-width: 228px">
        Welcome to Spext 🙂
        <br />
        To begin, upload a file below or start a project on the right.
      </div>
      <Button @click="$emit('showUploadModal')" classname="mt-4 pr-2.75">
        <span>Transcribe a file</span>
        <UploadIcon class="w-4 h-4 ml-2" />
      </Button>
    </div>

    <div v-else class="w-full">
      <div class="relative border-b border-primary-300">
        <input
          type="text"
          @focus="isFocused=true"
          @focusout="isFocused=false"
          v-model="filename"
          placeholder="Search files"
          class="block w-full py-3 pl-3 pr-10 text-sm border-none rounded-tr bg-primary-100 text-primary placeholder-primary-500 focus:ring-0 focus:border-primary-300"
        />
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-primary-700 hover:text-primary"
        >
          <SearchIcon classname="h-6 w-6" />
        </div>
      </div>

      <div class="ml-4 overflow-y-auto resultsContainer">
        <div
          v-for="(file, i) in files"
          :key="i"
          @click="selectFile(file.key)"
          class="border-b rounded-sm flex border-primary-300 mr-4 py-4.5 px-2.5 items-center justify-start last:border-transparent w-full relative"
          :class="[
            (($route.params.podId === file.key) || (selectedKey === file.key)) ? '' : 'group cursor-pointer hover:bg-primary-100',
            selectedKey === file.key ? 'bg-primary-300' : '',
          ]"
        >

          <BrandIcon
            class="mr-3 h-7 w-7 group-hover:text-primary-500"
            :class="[
              selectedKey===file.key ? 'text-primary' : 'text-primary-400',
            ]"
          />
          <div class="text-sm fileTitle">
            <div class="font-semibold text-primary overflow-ellipsis overflow-hidden whitespace-nowrap max-w-full">
              {{ file.title }}
            </div>
            <div v-show="$route.params.podId===file.key" class="text-blue">
              Currently Open
            </div>
          </div>
        </div>
      </div>

      <AddFilesFooter :config="footerConfig" @actionBtnClick="actionBtnClick" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/services/store'
import SearchIcon from '@/components/base/icons/Search.vue'
import AddFilesFooter from '../AddFileFooter/AddFileFooter'
import BrandIcon from '@/components/base/icons/Brand.vue'
import UploadIcon from '@/components/base/icons/Upload.vue'
import Button from '@/components/base/buttons/Button.vue'

export default {
  name: 'SpextDocs',
  components: {
    SearchIcon,
    BrandIcon,
    AddFilesFooter,
    UploadIcon,
    Button
  },
  data () {
    return {
      filename: '',
      isFocused: false,
      selectedKey: '',
      footerConfig: {
        message: `Uploaded files won't be transcribed here. To transcribe files, use the "Transcribe" button in the top bar`,
        showHereLink: false,
        actionBtns: {
          'addFile': {
            label: 'Add file',
            type: 'submit',
            isDisabled: true,
            isHidden: false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    }),
    isReplacing: function() {
      return this.modalProps.isReplacing
    },
    computedList () {
      return store.state.app.computedList
    },
    files () {
      let list = []
      this.filteredList.forEach(element => {
        // if ((this.filename === '' || element.title.toLowerCase().indexOf(this.filename.toLowerCase()) >= 0) && this.$route.params.podId !== element.key) {
        if ((this.filename === '' || String(element.title).toLowerCase().indexOf(this.filename.toLowerCase()) >= 0)) {
          list.push(element)
        }
      })
      return list
    },
    filteredList () {
      let list = []
      this.computedList.forEach(element => {
        if (element.code < 205) {
          return
        }
        list.push(element)
      })
      return list
    }
  },
  methods: {
    selectFile (key) {
      if (this.$route.params.podId === key) return
      this.selectedKey = key
      store.commit('modal/setSelectedFile', { type: 'doc', key: key })
      this.footerConfig.actionBtns['addFile'].isDisabled = false
    },
    actionBtnClick () {
      store.dispatch('modal/addFile', this.isReplacing)
    }
  }
}
</script>

<style lang="scss" scoped>
.resultsContainer {
  max-height: 312px;
}

.mw-custom {
  max-width: calc(100% - 150px);
}

.fileTitle {
  max-width: calc(100% - 40px);
}
</style>
