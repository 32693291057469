<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6041 15.3021L17.6809 16.0204L17.3744 17.7762C16.7231 21.4699 13.1539 23.5322 9.54008 22.8937C5.92622 22.2552 3.24775 19.0915 3.89901 15.3978L5.20792 8.29462L2.50391 7.81894L3.21263 3.79645L3.88943 3.91457C5.00679 4.1125 6.06988 3.36546 6.26781 2.2481L6.62218 0.230469L10.6447 0.939194L7.97578 16.113C7.82254 17.3995 8.7707 18.5456 10.2584 18.8074C11.7461 19.0724 13.0007 18.3157 13.2944 17.0515L13.6041 15.3021ZM20.224 3.81365L20.9998 6.42508L17.0412 7.60309L20.3581 10.0645L18.7332 12.2545L13.2421 8.18093L13.6699 5.76104L20.224 3.81365Z" />
  </svg>
</template>

<script>
export default {
  name: 'Brand',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
